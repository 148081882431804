import { template as template_6ea0d6e3284f49159a287c092c09fd38 } from "@ember/template-compiler";
const WelcomeHeader = template_6ea0d6e3284f49159a287c092c09fd38(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
